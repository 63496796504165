import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: start;
  align-items: center;
  width: 395px;
  background-color: ${({ theme }) => theme.colors.background.navbar};

  #control-image {
    min-height: 150px !important;
  }

  @media (max-width: 1220px) {
    gap: 0;
    padding-bottom: 8px;
    width: 100%;
    flex-direction: row;
    justify-content: center;

    #control-image-wrapper {
      flex-direction: row;
    }

    #control-image {
      height: 195px !important;
      order: 1;
    }

    #image-control {
      order: 2;
      flex-direction: column;
      gap: 4px;
    }

    button {
      order: 3;
    }
  }
`;
