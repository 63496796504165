import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import masked from '~/common/masked';
import { getPersonDetail, getPersonResetHistory } from '~/store/people/actions';
import { Table, TableColumns } from '~/ui/components';
import { ItemWrapper } from './ItemWrapper';

type ResetRow = {
  requestResetByUser: string;
  requestResetDate: string;
  requestResetReason: string;
  openRequestResetReason: string;
  resetByUser: string;
  resetDate: string;
};

type ResetsProps = {
  loading?: boolean;
};

export function Resets({ loading }: ResetsProps) {
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const person = getPersonDetailsControl.result();

  const resetHistoryControl = useAwaitControl(getPersonResetHistory);
  const resets = resetHistoryControl.result()?.entries || [];
  const resetLoading = resetHistoryControl.isRunning();

  const isLoading = useMemo(() => loading || resetLoading, [loading, resetLoading]);

  const columns: TableColumns<ResetRow> = [
    {
      accessorKey: 'requestResetByUser',
      header: () => 'SOLICITADO POR',
      maxSize: 300,
      cell: (data) => {
        if (data.row.original.requestResetByUser) {
          return <ItemWrapper value={data.row.original.requestResetByUser} />;
        }
        return null;
      },
    },
    {
      accessorKey: 'requestResetDate',
      header: () => 'DATA SOLIC.',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: (data) => {
        if (data.row.original.requestResetDate) {
          return masked.toDate(data.row.original.requestResetDate);
        }
        return null;
      },
    },
    {
      accessorKey: 'requestResetReason',
      header: () => 'MOTIVO',
      maxSize: 120,
      cell: (data) => {
        if (data.row.original.requestResetReason) {
          return <ItemWrapper value={data.row.original.requestResetReason} />;
        }
        return null;
      },
    },
    {
      accessorKey: 'openRequestResetReason',
      header: () => 'COMENTÁRIO',
      maxSize: 160,
      cell: (data) => {
        if (data.row.original?.openRequestResetReason) {
          return <ItemWrapper value={data.row.original.openRequestResetReason} />;
        }
        return null;
      },
    },
    {
      accessorKey: 'resetByUser',
      header: () => 'RESETADO POR',
      maxSize: 200,
      cell: (data) => {
        if (data.row.original.resetByUser) {
          return <ItemWrapper value={data.row.original.resetByUser} />;
        }
        return null;
      },
    },
    {
      accessorKey: 'resetDate',
      header: () => 'DATA RESET',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: (data) => {
        if (data.row.original.resetDate) {
          return masked.toDate(data.row.original.resetDate);
        }
        return null;
      },
    },
  ];

  useEffect(() => {
    if (person?.id && !resets.length) {
      resetHistoryControl.start({ id: person.id });
    }
  }, []);

  return (
    <Table
      noItemsMessage="Nenhum reset encontrado"
      loading={isLoading}
      data={resets}
      columns={columns}
    />
  );
}
