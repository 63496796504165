import React, { useCallback, useEffect } from 'react';
import { useModal } from '~/hooks/useModal';

import { Pendency as PendencyModal } from '~/screens/Person/components';
import { getAllPendencies } from '~/store/pendencies/actions';
import { getPersonDetail } from '~/store/people/actions';
import { Table, TableColumns, Tag } from '~/ui/components';
import { Pendency, PendencyStatusColors } from '~/typings/entities/Pendency';
import pendencyTitle from '~/typings/enums/PendencyType';

import {
  TabHeader,
  TabHeaderText,
  TabHeaderTitle,
  ActionButton,
} from '~/screens/Person/components/Tabs';
import moment from 'moment';
import { useAwaitControl } from 'react-redux-await-control';
import { getLoggedUser } from '~/store/user/actions';
import { Link } from './Tabs.styled';

interface PendenciesProps {
  loading?: boolean;
}

export function Pendencies({ loading }: PendenciesProps) {
  const { openModal } = useModal();

  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const person = getPersonDetailsControl.result();

  const getPendenciesControl = useAwaitControl(getAllPendencies);
  const pendencies = getPendenciesControl.result();

  const getLoggedUserControl = useAwaitControl(getLoggedUser);
  const roles = getLoggedUserControl.result()?.roles;
  const isAgent = roles?.includes('BACKOFFICE');

  const isActionDisabled = useCallback(
    (value: string): boolean => pendencyTitle(value) === 'Assinatura com selfie' && isAgent,
    [isAgent],
  );

  const handlePendencyDetailsModal = useCallback(
    (id) => {
      const pendency = pendencies.find((p) => p.id === id);
      openModal(<PendencyModal pendency={pendency} />, {
        id: 'pendency-details',
        title: 'Pendência',
        closable: true,
        width: 'auto',
      });
    },
    [openModal, pendencies],
  );

  const columns: TableColumns<Pendency> = [
    {
      accessorKey: 'contractId',
      header: () => 'CONTRATO',
      cell: (info) => {
        const value = info.getValue();
        if (value <= 0) {
          return <span>-</span>;
        }
        return <Link to={`/backoffice/contracts/${info.getValue()}`}>{info.getValue()}</Link>;
      },
    },
    {
      accessorKey: 'pendingDate',
      header: () => 'DATA',
      sortingFn: (rowA: any, rowB: any, columnId: any): number => {
        const dateA = moment(rowA.getValue(columnId));
        const dateB = moment(rowB.getValue(columnId));
        return dateA.isBefore(dateB) ? -1 : 1;
      },
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY'),
    },
    {
      accessorKey: 'status',
      header: () => 'STATUS',
      cell: (info) => {
        const statusValue: string = info.getValue<string>();
        return (
          <Tag rounded small color={PendencyStatusColors?.[statusValue as string] || 'alert'}>
            {statusValue}
          </Tag>
        );
      },
    },
    {
      accessorKey: 'pendingType',
      header: () => 'TIPO DA PENDÊNCIA',
      enableSorting: false,
      cell: (info) => pendencyTitle(info.getValue()),
    },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <ActionButton
          iconOnly
          disabled={isActionDisabled(info?.row?.original?.pendingType)}
          icon="Paperclip"
          variant="text"
          onClick={() => handlePendencyDetailsModal(info.row.original.id)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!pendencies?.length && person?.id) {
      getPendenciesControl.start({ personId: person?.id });
    }
  }, [person]);

  return (
    <>
      {!!pendencies?.length && (
        <TabHeader>
          <TabHeaderTitle>Pendências</TabHeaderTitle>

          <TabHeaderText>
            Para resolver uma pendência clique no status ou no botão para exibir toda informação
          </TabHeaderText>
        </TabHeader>
      )}

      <Table
        noItemsMessage="Nenhuma pendência encontrada"
        loading={loading}
        data={pendencies}
        columns={columns}
        initialSorting={[{ id: 'pendingDate', desc: true }]}
      />
    </>
  );
}
