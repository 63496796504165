import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '..';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const InfoLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'label',
  weight: 600,
  color: themeToggleColor(theme, 'neutral.primaryAlt', { dark: 'element.primary' }),
}))``;

export const InfoValue = styled(Typography).attrs(({ theme, color, weight, type }) => ({
  type: type || 'caption',
  element: 'span',
  weight: weight || 400,
  color: color || theme.colors.neutral.primaryAlt,
}))`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
