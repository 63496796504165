import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { CaretDown, Loading } from '~/ui/assets/icons';
import { CascaderProps } from 'antd/lib/cascader';
import { createPortal } from 'react-dom';
import includesStringSearch from '~/common/includesStringSearch';
import colors from '~/ui/theme/colors';
import {
  CustomComponentsAreaProps,
  PortalSelect,
  SelectLabel,
  StyledCascader,
} from './Select.styled';

type SelectProps = Omit<CascaderProps, 'options'> & {
  options: any[];
  label?: string;
  loading?: boolean;
  backgroundColor?: string;
  popupPlacement?: 'left' | 'right';
  testId?: string;
};

const Portal = (props: CustomComponentsAreaProps) =>
  createPortal(
    <PortalSelect id="mt-select-portal" {...props} />,
    document.getElementById('portal'),
  );

export const Select = forwardRef(
  (
    { label, loading, popupPlacement, placeholder, testId = 'cascader', ...props }: SelectProps,
    ref,
  ) => {
    const selectRef = useRef(null);
    const [dropdownWidth, setDropdownWidth] = useState<number | null>(0);

    function filter(inputValue, path) {
      const labelField = props.fieldNames?.label || 'label';
      return path.some((option) => includesStringSearch(option[labelField], inputValue));
    }

    const getPopupContainer = () => document.getElementById('mt-select-portal');

    useEffect(() => {
      if (selectRef.current) {
        setTimeout(() => {
          const inputWidth = selectRef.current.input.input.parentElement.offsetWidth;
          setDropdownWidth(inputWidth);
        }, 100);
      }
    }, [selectRef]);

    return (
      <>
        <SelectLabel data-testid={`${testId}-select-label`}>{label || placeholder}</SelectLabel>
        <StyledCascader
          ref={selectRef}
          notFoundContent="Nenhum resultado encontrado"
          size="large"
          suffixIcon={loading ? <Loading fill={colors.gray['800']} /> : <CaretDown />}
          showSearch={{ filter }}
          allowClear
          disabled={loading}
          {...props}
          label={null}
          placeholder={null}
          getPopupContainer={getPopupContainer}
          popupClassName={['mt-select-dropdown', props.popupClassName, popupPlacement]
            .filter(Boolean)
            .join(' ')}
          popupStyle={dropdownWidth ? { width: dropdownWidth } : {}}
          data-testid={`${testId}-select`}
        />

        <Portal newWidth={dropdownWidth} />
      </>
    );
  },
);
