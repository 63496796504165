import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import moment from 'moment';

import { getLoggedUser } from '~/store/user/actions';
import {
  getContractQueueInformation,
  listQueueContracts,
  clearQueue,
  updateActiveUserQueue,
  getUserActiveToQueue,
  activateUserContractQueueStatus,
  getContractToQueue,
} from '~/store/contracts/actions';

import AskContracts from '~/screens/DistributedContracts/AskContracts';
import screenRegister from '~/hoc/screenRegister';
import { Spinner } from '~/components';
import { ContractDetailsContext } from '~/contexts/ContractDetailsContext';

import {
  ActivateDistributionConfig,
  HoursConfig,
  ProductConfig,
  AlertTag,
  QueueInfo,
  UpdateTimer,
  ContractTable,
  ProfileConfig,
} from './components';

import {
  ConfigContainer,
  ConfigLabel,
  Container,
  Content,
  Footer,
  TimerContainer,
  Title,
} from './styled';

const UPDATE_TIME = 25_000;

function DistributedContracts() {
  const [loading, setLoading] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const {
    isDistributionActive,
    contract,
    hourFilter,
    queueFilter,
    allStepsConcluded,
    checkUserQueueStatus,
  } = useContext(ContractDetailsContext);
  const [nextUpdate, setNextUpdate] = useState(moment().add(UPDATE_TIME));

  const [
    getLoggedUserControl,
    getContractQueueInformationControl,
    listQueueContractsControl,
    getContractToQueueControl,
    clearQueueControl,
    updateActiveUserQueueControl,
    getUserActiveToQueueControl,
    activateUserContractQueueStatusControl,
  ] = useAwaitControl([
    getLoggedUser,
    getContractQueueInformation,
    listQueueContracts,
    getContractToQueue,
    clearQueue,
    updateActiveUserQueue,
    getUserActiveToQueue,
    activateUserContractQueueStatus,
  ]);

  const contracts = listQueueContractsControl.result();
  const userId = getLoggedUserControl.result()?.id;
  const queueFailure = listQueueContractsControl.hasFailure();
  const clearQueueSuccess = clearQueueControl.isSuccessful();
  const listContractRunning = listQueueContractsControl.isRunning();

  const isActiveUserQueueInfoLoading = getUserActiveToQueueControl.isRunning();
  const hasActiveQueue = getUserActiveToQueueControl.result();

  const loadContracts = useCallback(() => {
    if (isDistributionActive && allStepsConcluded) {
      listQueueContractsControl.start({ product: contract });
    }
  }, [isDistributionActive, allStepsConcluded, contract]);

  const loadQueueInformations = useCallback(() => {
    if (hourFilter) {
      getContractQueueInformationControl.start({ type: hourFilter, kycStatus: queueFilter });
    }
  }, [hourFilter, queueFilter]);

  const getNewContracts = useCallback((queueConfigs) => {
    getContractToQueueControl.start(queueConfigs);
  }, []);

  const handleAutoLoad = useCallback(() => {
    if (contract) {
      getNewContracts({
        contractQueueStatus: contract,
        typeHours: hourFilter,
        kycStatus: queueFilter,
        requestedNumber: 1,
      });
    } else {
      loadQueueInformations();
    }
  }, [hourFilter, contract, queueFilter]);

  const updateNextLoad = useCallback(() => {
    setNextUpdate(moment().add(UPDATE_TIME));
  }, []);

  useEffect(() => {
    if (!userId || isActiveUserQueueInfoLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userId, isActiveUserQueueInfoLoading, hasActiveQueue]);

  useEffect(() => {
    if (clearQueueSuccess) {
      updateNextLoad();
    }
  }, [clearQueueSuccess]);

  useEffect(() => {
    loadContracts();
    const interval = setInterval(updateNextLoad, UPDATE_TIME);

    loadContracts();
    loadQueueInformations();

    return () => {
      clearInterval(interval);
      listQueueContractsControl.cancel();
    };
  }, []);

  useEffect(() => {
    if (userId) {
      checkUserQueueStatus(userId);
    }
  }, [userId]);

  useEffect(() => {
    const FIVE_SECONDS = 5000;

    if ((alertStatus && contract) || !isDistributionActive) {
      setAlertStatus(false);
    }

    const timeoutId = setTimeout(() => {
      if (hourFilter && !contract) {
        setAlertStatus(true);
      }
    }, FIVE_SECONDS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isDistributionActive, hourFilter, contract]);

  useEffect(() => {
    if (!hasActiveQueue && allStepsConcluded) {
      activateUserContractQueueStatusControl.start({
        userId,
        queueStatus: contract,
        type: hourFilter,
        kycStatus: queueFilter,
      });
    }

    if (allStepsConcluded && hasActiveQueue) {
      listQueueContractsControl.clear();
      updateActiveUserQueueControl.start({
        userId,
        queueStatus: contract,
        type: hourFilter,
        kycStatus: queueFilter,
      });
    }

    if (allStepsConcluded) {
      getNewContracts({
        contractQueueStatus: contract,
        typeHours: hourFilter,
        kycStatus: queueFilter,
        requestedNumber: 1,
      });
    }

    loadQueueInformations();
  }, [allStepsConcluded, contract, hourFilter, queueFilter]);

  return (
    <Container>
      <Spinner spinning={loading} />
      <Title>Configurações de Contratos</Title>
      <ConfigContainer>
        <ActivateDistributionConfig />

        <HoursConfig />

        <ProfileConfig />

        <ProductConfig />

        {allStepsConcluded && !queueFailure ? (
          <AlertTag
            label="Tudo certo! Aguarde alguns segundos e seus contratos serão exibidos."
            status="success"
          />
        ) : (
          ''
        )}
        {alertStatus && (
          <AlertTag
            label="Atenção, é necessário preencher todos os campos para funcionar."
            status="warning"
          />
        )}
        {queueFailure && (
          <AlertTag
            renderValue={
              <ConfigLabel>
                Ops, aconteceu algum erro:( continue com os contratos de <b>forma manual</b>, por
                favor.
              </ConfigLabel>
            }
            status="error"
          />
        )}
      </ConfigContainer>
      <TimerContainer>
        <Title>Informações dos contratos</Title>
        <UpdateTimer
          nextUpdate={nextUpdate}
          load={handleAutoLoad}
          isLoading={listContractRunning}
          updateEndTime={updateNextLoad}
          disabled={!contract}
        />
      </TimerContainer>
      <Content>
        <ContractTable contracts={contracts} />
        <Footer>
          <QueueInfo
            loadQueueInformations={loadQueueInformations}
            getNewContracts={getNewContracts}
          />
        </Footer>
      </Content>
    </Container>
  );
}

export default screenRegister({
  screenName: 'DistributedContracts',
  path: '/backoffice/distributed-contracts',
  headerTitle: 'Conferência de contratos',
  modals: [AskContracts],
  headerMenu: true,
  headerMenuPosition: 2,
})(DistributedContracts);
