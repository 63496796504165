import { createAsyncAction } from 'react-redux-await-control';
import { message } from 'antd';
import { telli } from '~/services';

export const listTags = createAsyncAction('LIST_TAGS', {
  initialValue: [],
  saveResult: true,
  context: {
    onRequestError: () => message.error('Erro ao carregar as tags!'),
    responseParser: ({ data }) => data.resource,
    request: ({ externalId, type }) => telli.get(`/admin/tag`, { params: { externalId, type } }),
  },
});
export const listTagsTypes = createAsyncAction('LIST_TAGS_TYPES', {
  initialValue: [],
  saveResult: true,
  context: {
    responseParser: ({ data }) => data.resource,
    request: ({ type }) => telli.get(`/admin/tag/tagtype`, { params: { type } }),
  },
});

export const removeTag = createAsyncAction('REMOVE_TAG', {
  context: {
    onRequestError: () => message.error('Erro ao remover tag'),
    onRequestSuccess: () => message.success('Tag removida'),
    request: ({ id }) => telli.delete(`/admin/tag`, { params: { id } }),
  },
});

export const deleteTag = createAsyncAction('DELETE_TAG', {
  context: {
    onRequestError: () => message.error('Erro ao excluir tag'),
    onRequestSuccess: () => message.success('Tag excluída'),
    request: ({ id }) => telli.delete(`/admin/tag/tagtype`, { params: { id } }),
  },
});

export const addTag = createAsyncAction('ADD_TAG', {
  context: {
    onRequestError: () => message.error('Erro ao adicionar tag'),
    onRequestSuccess: () => message.success('Tag adicionada'),
    request: (params) => telli.post(`/admin/tag`, params),
  },
});
