import React, { useCallback } from 'react';
import * as icons from '~/ui/assets/icons';
import getInitials from '~/common/getInitials';
import { Reports } from '~/ui/components/Menu/Reports/Reports';
import BackofficeLogo from '../../assets/imgs/backoffice.svg';
import LogoSmall from '../../assets/imgs/logoSmall.svg';

import { Settings } from './Settings/Settings';
import { Notification } from './Notification/Notification';
import { SessionTimer } from './SessionTimer/SessionTimer';

import {
  Navbar,
  NavbarLogo,
  NavbarMenu,
  NavbarButton,
  NavbarItem,
  Caret,
  Submenu,
  SubItem,
  SubmenuWrapper,
  ButtonLine,
  ProfilePicture,
  RightNavbar,
} from './Menu.styled';

type IconsTypes = keyof typeof icons;

export interface MenuItem {
  title: string;
  icon?: IconsTypes;
  items?: MenuItem[];
  path?: string;
}

interface MenuProps {
  items: MenuItem[];
  profileName?: string;
}

export function Menu({ items, profileName }: MenuProps) {
  const getIcon = useCallback((icon: IconsTypes) => {
    const Icon = icons[icon];

    return <Icon />;
  }, []);

  const handleNavbarClick = (item) => (e) => {
    if (!item.path) {
      e.preventDefault();
    }
  };

  return (
    <Navbar>
      <NavbarLogo>
        <BackofficeLogo />
        <LogoSmall className="mobile" />
      </NavbarLogo>

      <NavbarMenu>
        {items?.map((item) => (
          <React.Fragment key={item.title}>
            <NavbarItem>
              <NavbarButton to={item.path || '/'} onClick={handleNavbarClick(item)}>
                <>
                  {getIcon(item.icon)}
                  {item.title}
                  <ButtonLine className="bottom-line" />
                </>
              </NavbarButton>

              {item?.items?.length && (
                <>
                  {item?.items?.length && <Caret className="caret" />}
                  <SubmenuWrapper className="submenu">
                    <Submenu>
                      {item?.items.map((subItem) => (
                        <React.Fragment key={subItem.title}>
                          <SubItem to={subItem.path || '/'}>
                            {getIcon(subItem.icon)}

                            {subItem.title}
                          </SubItem>
                        </React.Fragment>
                      ))}
                    </Submenu>
                  </SubmenuWrapper>
                </>
              )}
            </NavbarItem>
          </React.Fragment>
        ))}
      </NavbarMenu>

      <RightNavbar>
        <SessionTimer />
        <ProfilePicture content={profileName || '-'}>
          {getInitials(profileName) || '-'}
        </ProfilePicture>
        {/* <Notification trigger="click" /> */}
        <Reports trigger="click" />
        <Settings trigger="click" />
      </RightNavbar>
    </Navbar>
  );
}
