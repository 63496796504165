import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';

import { useModal } from '~/hooks/useModal';
import toMoney from '~/common/masked/toMoney';
import LoanType, { LOAN_TYPES } from '~/typings/enums/LoanType';
import ContractStatus, {
  CONTRACT_STATUS_COLOR,
  INSURANCE_STATUS_COLOR,
} from '~/typings/enums/ContractStatus';
import { ContractHistory } from '~/screens/Person/components';
import { Button, CellProps, Table, TableColumns, Tag, Tooltip } from '~/ui/components';
import { useTheme } from 'styled-components';
import { useDrawer } from '~/hooks/useDrawer';
import { useAwaitControl } from 'react-redux-await-control';
import { getContractsOfPerson, getPersonDetail } from '~/store/people/actions';
import { Person } from '~/typings/entities/person';
import { ActionButton } from '.';
import { CellWrapper, FlexWrapper, StyledButton, Link } from './Tabs.styled';
import { InsuranceDetails } from './InsuranceDetails/InsuranceDetails';

type ContractRow = {
  contractDate: string;
  contractId: string;
  contractType: string;
  contractValue: string;
  createdLocation: string;
  financialInstitution: string;
  installmentsValue: string;
  loanType: string;
  operationStep: string;
  contractSituation: string;
  workCode: string;
  workName: string;
  insurance?: boolean;
};

const CARD_PRODUCTS = [LOAN_TYPES.CONSIGNED_CARD_RMC, LOAN_TYPES.BENEFIT_CARD_RCC];

interface ContractsProps {
  loading?: boolean;
}

export function Contracts({ loading }: ContractsProps) {
  const { openModal } = useModal();
  const { openDrawer } = useDrawer();
  const theme = useTheme();

  const personControl = useAwaitControl(getPersonDetail);
  const contractsControl = useAwaitControl(getContractsOfPerson);
  const contracts = contractsControl.result();

  const contractLoading = contractsControl.isRunning();
  const personLoading = personControl.isRunning();

  const person: Person = personControl.result();

  const isLoading = useMemo(
    () => contractLoading || personLoading || loading,
    [contractLoading, personLoading, loading],
  );

  const normalizedContracts = contracts?.entries?.map((contract) => ({
    ...contract,
    children:
      contract.insurances?.map((insurance) => ({
        insurance: true,
        contractId: insurance.id,
        contractDate: insurance.initialDate,
        contractSituation: insurance.status,
        loanType: insurance.type,
        contractValue: insurance.value,
      })) || [],
  }));

  const handleHistoryModal = useCallback(
    (id, product?, externalId?) => () => {
      openModal(<ContractHistory id={id} product={product} externalId={externalId} />, {
        title: 'Histórico de contrato',
        closable: true,
        width: 550,
      });
    },
    [openModal],
  );

  const renderLabelWithInfo = useCallback(
    (label, info) => (
      <FlexWrapper>
        <CellWrapper>{label ?? '-'}</CellWrapper>
        {info && <Tooltip content={info} placement="bottomLeft" maxWidth="350px" />}
      </FlexWrapper>
    ),
    [],
  );

  const handleContractDetails = useCallback((insurance) => {
    openDrawer('insuranceDetails', <InsuranceDetails id={insurance.contractId} />, {
      title: 'Seguro Renda Protegida',
      closable: true,
      width: 550,
    });
  }, []);

  const renderLink = useCallback(
    (info) => {
      const contractId = info.getValue();
      const row = info.row.original;

      const ALLOWED_PRODUCTS = [...CARD_PRODUCTS, LOAN_TYPES.PIX_CREDIT_CARD];

      if (row?.insurance) {
        return <StyledButton title={contractId} onClick={() => handleContractDetails(row)} />;
      }

      if (ALLOWED_PRODUCTS.includes(row.loanType)) {
        return <Link to={`/backoffice/contract/${contractId}`}>{contractId}</Link>;
      }

      return <Link to={`/backoffice/contracts/${contractId}`}>{contractId}</Link>;
    },
    [person],
  );

  const renderHistory = useCallback(
    (contractId, row) => (
      <ActionButton
        onClick={handleHistoryModal(contractId, row?.loanType, row?.externalId)}
        iconOnly
        icon="FileSearch"
        variant="text"
        disabled={row?.insurance || row?.loanType === LOAN_TYPES.PIX_CREDIT_CARD}
      />
    ),
    [],
  );

  function cellToDetails(props: CellProps<ContractRow>) {
    const { contractId, loanType } = props.row.original;

    return (
      <Link to={`/backoffice/card/${contractId}`} disabled={!CARD_PRODUCTS.includes(loanType)}>
        <Button iconOnly icon="Eye" variant="text" disabled={!CARD_PRODUCTS.includes(loanType)} />
      </Link>
    );
  }

  const columns: TableColumns<ContractRow> = [
    {
      accessorKey: 'contractId',
      header: () => 'COD',
      cell: (info) => renderLink(info),
    },
    {
      accessorKey: 'contractDate',
      header: () => 'DATA',
      sortingFn: (rowA: any, rowB: any, columnId: any): number => {
        const dateA = moment(rowA.getValue(columnId));
        const dateB = moment(rowB.getValue(columnId));
        return dateA.isBefore(dateB) ? -1 : 1;
      },
      cell: ({ row }) => moment(row.original.contractDate).format('DD/MM/YY'),
    },
    {
      accessorKey: 'installmentsValue',
      header: () => 'PARCELA',
      accessorFn: ({ installmentsValue }) => (installmentsValue ? toMoney(installmentsValue) : '-'),
    },
    {
      accessorKey: 'contractSituation',
      header: () => 'SITUAÇÃO',
      cell: ({ row }) => {
        const { contractSituation, insurance } = row.original;
        const pallete =
          (insurance
            ? INSURANCE_STATUS_COLOR[contractSituation]
            : CONTRACT_STATUS_COLOR[contractSituation]) || 'neutral';
        const bgColor = theme.colors?.[pallete]?.secondary;
        const textColor = theme.colors?.[pallete]?.primaryAlt;

        if (contractSituation) {
          return (
            <Tag small rounded bgColor={bgColor} textColor={textColor}>
              {insurance ? contractSituation : ContractStatus({ value: contractSituation })}
            </Tag>
          );
        }

        return '-';
      },
    },
    {
      accessorKey: 'loanType',
      header: () => 'TIPO',
      accessorFn: ({ loanType }) => LoanType({ value: loanType }),
    },
    {
      accessorKey: 'contractValue',
      header: () => 'VALOR',
      accessorFn: (value) => (value?.contractValue ? toMoney(value.contractValue) : '-'),
      sortingFn: (rowA: any, rowB: any, columnId: any): number => {
        const valueA = Number(rowA.getValue(columnId).replace(/\D/g, ''));
        const valueB = Number(rowB.getValue(columnId).replace(/\D/g, ''));
        return valueA < valueB ? -1 : 1;
      },
    },
    {
      accessorKey: 'benefitCode',
      header: () => 'BENEFÍCIO',
      minSize: 80,
      cell: (info) => renderLabelWithInfo(info.row.original.workCode, info.row.original.workName),
    },
    {
      id: 'history',
      header: () => 'HISTÓRICO',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => renderHistory(info.row.original.contractId, info.row.original),
    },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: cellToDetails,
    },
  ];

  useEffect(() => {
    if (person?.id && !contracts?.entries?.length) {
      contractsControl.start({ id: person.id });
    }
  }, [person]);

  return (
    <Table
      noItemsMessage="Nenhum contrato encontrado"
      responsiveCols={['contractId', 'contractDate', 'loanType', 'contractSituation', 'history']}
      loading={isLoading}
      data={normalizedContracts}
      columns={columns}
      initialSorting={[{ id: 'contractDate', desc: true }]}
    />
  );
}
