import { createAsyncAction } from 'react-redux-await-control';
import { message } from 'antd';

import { telli } from '~/services';

export const adjustContract = createAsyncAction('CONSIGNEE_ADJUST_CONTRACT', {
  saveResult: true,
  context: {
    request: ({ contractId, data }) =>
      telli.post(`/admin/contractcustom/contracts/${contractId}/adjustcontract`, data),
    onRequestSuccess: () => message.success('Informações salvas com Sucesso!'),
    onRequestError: () => message.error('Falha ao salvar informações'),
  },
});

export const sendContract = createAsyncAction('CONSIGNEE_SEND_CONTRACT', {
  saveResult: true,
  context: {
    request: ({ contractId, data }) =>
      telli.post(`/admin/contractcustom/contracts/${contractId}/sendtoconsignee`, data),
    onRequestSuccess: () => message.success('Contrato enviado com Sucesso!'),
    onRequestError: () => message.error('Falha ao enviar contrato'),
  },
});
