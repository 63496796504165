/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useMemo } from 'react';
import { getEnrollmentByUserId } from '~/store/enrollments/actions';
import { Table, TableColumns, Tooltip } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';

import { Enrollment } from '~/typings/entities/Enrollment';
import { useModal } from '~/hooks/useModal';
import { getPersonDetail } from '~/store/people/actions';
import MarginDetails from './components/Popovers/MarginDetails';
import { ActionButton } from '..';
import { EnrollmentsWrapper, CellWrapper, TitleWrapper } from './Enrollment.styled';
import LastUpdates from './components/Popovers/LastUpdates';
import Details from './components/Details/Details';
import Actions from './components/Actions/Actions';

interface EnrollmentsProps {
  loading?: boolean;
}

export function Enrollments({ loading }: EnrollmentsProps) {
  const { openModal } = useModal();

  const getEnrollmentByUserIdControl = useAwaitControl(getEnrollmentByUserId);
  const enrollments: Enrollment[] = getEnrollmentByUserIdControl.result();
  const enrollmentLoading = getEnrollmentByUserIdControl.isRunning();

  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const personDetails = getPersonDetailsControl.result();

  const isLoading = useMemo(() => loading || enrollmentLoading, [loading, enrollmentLoading]);

  const handleOnClickDetails = useCallback(
    (e, row) => {
      openModal(<Details enrollment={row} personDetails={personDetails} />, {
        title: `Detalhes ${row.product}`,
        id: 'enrollment-details-modal',
        closable: true,
        width: 800,
        className: 'max-height-modal',
      });
    },
    [personDetails],
  );

  const columns: TableColumns<Enrollment> = [
    {
      accessorKey: 'product',
      header: () => 'PRODUTO',
      cell: (info) => <CellWrapper>{info.row.original.product}</CellWrapper>,
    },
    {
      accessorKey: 'margin',
      header: () => (
        <>
          <TitleWrapper>MARGENS</TitleWrapper>
          <Tooltip
            title="Margem dos contratos"
            content="Visualize a margem de cada contrato e as últimas atualizações clicando no icone ao lado"
            placement="bottomLeft"
            maxWidth="350px"
          />
        </>
      ),
      cell: (info) => <MarginDetails data={info.row.original.marginInfo} />,
    },
    {
      id: 'benefitCode',
      header: () => 'COD DO BENEFÍCIO',
      cell: (info) => (
        <>
          <CellWrapper>{info.row.original.workCode ?? '-'}</CellWrapper>
          {info.row.original.workName && (
            <Tooltip content={info.row.original.workName} placement="bottomLeft" maxWidth="350px" />
          )}
        </>
      ),
    },
    {
      id: 'updates',
      header: () => 'ATUALIZAÇÕES',
      ellipsis: true,
      maxSize: 220,
      cell: (info) => <LastUpdates enrollment={info.row.original} person={personDetails} />,
    },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <ActionButton
          onClick={(e) => handleOnClickDetails(e, info.row.original)}
          iconOnly
          icon="FileSearch"
          variant="text"
        />
      ),
    },
    {
      id: 'actions',
      header: () => 'AÇÕES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => <Actions personDetails={personDetails} enrollment={info.row.original} />,
    },
  ];

  useEffect(() => {
    if (personDetails.id && !enrollments.length) {
      getEnrollmentByUserIdControl.start({ personId: personDetails.id });
    }
  }, [personDetails]);

  return (
    <EnrollmentsWrapper>
      <Table
        noItemsMessage="Nenhuma matrícula encontrada"
        loading={isLoading}
        data={enrollments}
        columns={columns}
      />
    </EnrollmentsWrapper>
  );
}
