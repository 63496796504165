import styled, { css } from 'styled-components';
import { Form } from 'antd';
import { themeColor } from '~/common/utils/theme';

type LoadingProp = {
  loading?: boolean;
};

export const StyledForm = styled(Form)<LoadingProp>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  max-width: ${({ width }) =>
    (width && typeof width === 'string' ? width : `${width}px`) || 'none'};

  .ant-form-explain {
    color: ${({ theme }) => theme.colors.negative.primaryAlt};
    font-size: ${({ theme }) => theme.typography.types.bodyXSmall.size};
    line-height: ${({ theme }) => theme.typography.types.bodyXSmall.lineHeight};
    margin-top: 2px;
    min-height: none;

    b {
      font-weight: 500;
    }
  }

  .ant-form-item {
    width: 100%;
    margin: 0;

    .has-error {
      input {
        border-width: 0;
        transition: none;
        background-color: ${themeColor('background.secondary', {
          dark: 'background.primary',
        })};
        color: ${({ theme }) => theme.colors.element.primary};
        border-color: transparent;

        &:focus,
        &:active,
        &:hover {
          outline: none;
          box-shadow: none;
          border-color: transparent;
        }
      }
    }

    .mt-date-picker {
      input {
        background-color: transparent;
        border-color: transparent;
        border-bottom: none !important;
      }
    }

    label {
      position: absolute;
    }

    &.no-input {
      margin: 0 !important;

      .ant-form-item-label {
        label {
          margin-bottom: 0;
        }
      }
    }
  }

  .ant-form-item-label {
    & label {
      font-size: ${({ theme }) => theme.typography.types.paragraphSmall.size};
      line-height: ${({ theme }) => theme.typography.types.paragraphSmall.lineHeight};
      font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
      font-weight: 500;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      text-align: left;
      text-wrap: wrap;
      color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    }
  }

  .ant-form-item-control {
    line-height: normal;
  }

  ${({ loading }) =>
    loading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const InputList = styled.div.attrs({
  className: 'input-list',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
`;

export const ExtraContent = styled.div.attrs({
  className: 'extra-content',
})``;

type FlexProp = {
  widthValue?: number;
  hasTitle?: boolean;
};

export const Flex = styled.div<FlexProp>`
  display: contents;

  ${({ hasTitle }) =>
    hasTitle &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: end;
      gap: 8px;
    `}

  width: 100%;
  max-width: ${({ widthValue }) =>
    (widthValue && typeof widthValue === 'string' ? widthValue : `${widthValue}px`) || 'none'};
`;
