import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { getContractsOfPerson, getPersonDetail, getPersonImageUrl } from '~/store/people/actions';
import { getClientDetails, getScores, listClixContracts } from '~/store/clix/actions';
import { WarrantyTypeNames } from '~/typings/enums/Clix';
import { Card, Redeem, Wallet } from '~/ui/assets/icons';
import screenRegister, { HelmetTitle } from '~/hoc/screenRegister';
import { Content, Tabs } from '~/ui/components';
import { ClixActions, ProfileWithActions, ResponsiveMainActions } from '~/components';
import { formatName } from '~/common';

import { ClixMobileActions } from '~/components/ProfileResume/MobileActions/ClixMobileActions';
import { TabShimmer } from '~/ui/components/Tabs/TabShimmer/TabShimmer';

import { CleanWarranty, CreditWarranty, InssWarranty } from './Tabs';
import { ClientInfo } from './Sections';

type GeneralInfoProps = {
  match?: any;
};

function ContractClixDetails({ match }: GeneralInfoProps) {
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getPersonImageControl = useAwaitControl(getPersonImageUrl);
  const getContractsOfPersonControl = useAwaitControl(getContractsOfPerson);
  const getClixDetailsControl = useAwaitControl(getClientDetails);
  const getScoresControl = useAwaitControl(getScores);
  const getClixContractsControl = useAwaitControl(listClixContracts);

  const person = getPersonDetailsControl.result();
  const products = getClixContractsControl.result();
  const avatarImage = getPersonImageControl.result('FACE_FRONT');
  const personDetailsLoading = getPersonDetailsControl.isRunning();
  const personDetailsSuccessful = getPersonDetailsControl.isSuccessful();
  const productsSuccessful = getClixContractsControl.isSuccessful();

  const personId = match?.params?.personId;
  const loading = personDetailsLoading || !productsSuccessful;

  const hasProduct = (product: string) =>
    !!products?.contracts?.find((contract) => contract?.warrantyType === product);

  useEffect(() => {
    if (personDetailsSuccessful) {
      const { document } = person;
      getScoresControl.start({ document });
      getClixContractsControl.start({ document });
    }
  }, [personDetailsSuccessful]);

  useEffect(() => {
    getPersonDetailsControl.start({ id: personId });
    getPersonImageControl.start({ id: personId, type: 'FACE_FRONT' }, { actionId: 'FACE_FRONT' });
    getContractsOfPersonControl.start({ id: personId });

    return () => {
      getPersonDetailsControl.clear();
      getClixDetailsControl.clear();
      getContractsOfPersonControl.clear();
      getScoresControl.clear();
    };
  }, []);

  return (
    <>
      <HelmetTitle title={person?.name ? `Clix - ${formatName(person?.name, 2)}` : 'Clix'} />

      <ProfileWithActions loading={loading} person={person} avatarImage={avatarImage}>
        <ClixActions loading={loading} />
      </ProfileWithActions>

      <Content>
        <ResponsiveMainActions
          person={person}
          loading={loading}
          avatarImage={avatarImage}
          responsiveActions={<ClixMobileActions />}
        />

        <ClientInfo loading={loading} person={person} />

        {!productsSuccessful && <TabShimmer />}

        {productsSuccessful && (
          <Tabs
            loading={loading}
            type="card"
            persistOnQuery={{ key: 'warranty' }}
            fullWidth
            tabs={[
              {
                key: 'CREDIT',
                disabled: !hasProduct('CREDIT'),
                icon: <Card width={22} height={22} />,
                title: WarrantyTypeNames.CREDIT,
                component: <CreditWarranty personId={personId} />,
              },
              {
                key: 'INSS',
                title: WarrantyTypeNames.INSS,
                disabled: !hasProduct('INSS'),
                icon: <Redeem width={22} height={22} />,
                component: <InssWarranty personId={personId} />,
              },
              {
                key: 'CLEAN',
                disabled: !hasProduct('CLEAN'),
                title: WarrantyTypeNames.CLEAN,
                icon: <Wallet width={22} height={22} />,
                component: <CleanWarranty personId={personId} />,
              },
            ]}
          />
        )}
      </Content>
    </>
  );
}

export default screenRegister({
  screenName: 'ClixContractDetails',
  path: '/backoffice/clix/:personId',
  headerTitle: 'Clix',
  customLayout: true,
})(ContractClixDetails);
