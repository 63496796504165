import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useDebounceCallback } from 'usehooks-ts';

import screenRegister from '~/hoc/screenRegister';
import { getTransactionHistory } from '~/store/cards/actions';
import { getPersonDetail, getPersonImageUrl } from '~/store/people/actions';
import { Breadcrumb, SearchBar, Select, SelectDate, Typography } from '~/ui/components';
import { Filters, ProfileWithActions, ResponsiveMainActions } from '~/components';
import { ClixMobileActions } from '~/components/ProfileResume/MobileActions/ClixMobileActions';

import { ContentWithBreadcrumb, Flex, Section } from './TransactionHistory.styles';
import SideBarActions from '../SideBarActions/SideBarActions';
import TransactionTable from '../components/TransactionTable/TransactionTable';

type TransactionHistoryProps = {
  match: any;
};

function TransactionHistory({ match }: TransactionHistoryProps) {
  const filterRef = useRef<any>();

  const transactionsControl = useAwaitControl(getTransactionHistory);
  const transactionsResult = transactionsControl.result();
  const transactionsLoading = transactionsControl.isRunning();

  const personControl = useAwaitControl(getPersonDetail);
  const person = personControl.result();
  const personLoading = personControl.isRunning();

  const personImageControl = useAwaitControl(getPersonImageUrl);
  const avatarImage = personImageControl.result('FACE_FRONT');

  const { contractId } = match.params;

  const loading = useMemo(
    () => transactionsLoading || personLoading,
    [transactionsLoading, personLoading],
  );

  const statusOptions = useMemo(
    () => [
      { label: 'Aprovado', value: 'approved' },
      { label: 'Reprovado', value: 'rejected' },
      { label: 'Pendente', value: 'pending' },
    ],
    [],
  );

  const typeExitOptions = useMemo(
    () => [
      { label: 'Saque', value: 'withdraw' },
      { label: 'Pagamento', value: 'payment' },
      { label: 'Transferência', value: 'transfer' },
    ],
    [],
  );

  const contractsUrl = useMemo(
    () => `/backoffice/people/${transactionsResult?.personId}?aux=contratos`,
    [transactionsResult],
  );

  const handleOnFilter = useCallback((values) => {
    transactionsControl.start(values);
  }, []);

  const handleOnResetFilter = useCallback(() => {
    transactionsControl.start();
  }, []);

  const handleSearch = useDebounceCallback((value) => {
    filterRef?.current?.reset();
    transactionsControl.start({ id: value });
  }, 500);

  const inputFilters = useMemo(
    () => [
      {
        id: 'status',
        input: <Select options={statusOptions} placeholder="Status da transação" allowClear />,
      },
      {
        id: 'dateTime',
        input: <SelectDate placeholder="Data da transação" />,
      },
      {
        id: 'typeExit',
        input: <Select options={typeExitOptions} placeholder="Tipo de saída" allowClear />,
      },
    ],
    [],
  );

  useEffect(() => {
    transactionsControl.start({ id: contractId });
  }, []);

  useEffect(() => {
    if (transactionsResult?.personId) {
      personControl.start({ id: transactionsResult.personId });
    }
  }, [transactionsResult]);

  return (
    <>
      <ProfileWithActions loading={loading} person={person} avatarImage={avatarImage}>
        <SideBarActions />
      </ProfileWithActions>

      <ContentWithBreadcrumb>
        <Breadcrumb
          items={[
            { label: 'Contratos', href: contractsUrl },
            { label: 'Detalhes de cartão', href: `/backoffice/card/${contractId}` },
            { label: 'Histórico de transações' },
          ]}
        />

        <ResponsiveMainActions
          person={person}
          loading={loading}
          avatarImage={avatarImage}
          responsiveActions={<ClixMobileActions />}
        />

        <Section>
          <Typography type="paragraphLarge" weight={600}>
            Histórico de transações
          </Typography>

          {/* <Flex> */}
          {/*  <SearchBar placeholder="Pesquise N. da transação" onChange={handleSearch} /> */}

          {/*  <Filters */}
          {/*    ref={filterRef} */}
          {/*    filters={inputFilters} */}
          {/*    onSubmit={handleOnFilter} */}
          {/*    onReset={handleOnResetFilter} */}
          {/*  /> */}
          {/* </Flex> */}

          <TransactionTable tableData={transactionsResult?.transactions} loading={loading} />
        </Section>
      </ContentWithBreadcrumb>
    </>
  );
}

export default screenRegister({
  screenName: 'TransactionHistory',
  path: '/backoffice/card/:contractId/transaction-history',
  headerTitle: 'Histórico de transações',
  customLayout: true,
})(TransactionHistory);
