import React, { useEffect } from 'react';

import { useAwaitControl } from 'react-redux-await-control';
import { getContractDetails } from '~/store/contracts/actions';
import { Contract } from '~/typings/entities/contract';
import { fetchFinancialInstitutionsForDeposit } from '~/store/utilities/actions';
import { Form } from './Form/Form';
import { Image } from './Image/Image';
import { Flex } from './Consignee.styled';

type ConsigneeProps = {
  contractId: number;
  loanType: string;
};

export function Consignee({ contractId, loanType }: ConsigneeProps) {
  const getContractDetailsControl = useAwaitControl(getContractDetails);
  const getFinancialInstitutionsControl = useAwaitControl(fetchFinancialInstitutionsForDeposit);

  const contract: Contract = getContractDetailsControl.result();

  useEffect(() => {
    if (contractId) {
      getFinancialInstitutionsControl.start({ contractId });
    }
  }, []);

  return (
    <Flex>
      <Form contract={contract} loanType={loanType} />
      <Image id={contractId} loanType={loanType} />
    </Flex>
  );
}
