import styled from 'styled-components';

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
`;

export const Content = styled.div`
  display: flex;
  padding: 12px;
  width: 100%;

  .mt-alert {
    min-width: 0px;
  }
`;
