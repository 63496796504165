import React from 'react';
import { InfoLabel, InfoValue, Wrapper } from './InfoGroup.styled';

interface InfoGroupProps {
  label: string;
  value: string | number | React.ReactElement;
  valueType?: string;
  valueWeight?: number;
  valueColor?: string;
  copyText?: string;
}

export function InfoGroup({
  label,
  value,
  valueType,
  valueWeight,
  valueColor,
  copyText,
}: InfoGroupProps) {
  const isReactElement = React.isValidElement(value);

  return (
    <Wrapper>
      <InfoLabel>{label}</InfoLabel>
      {isReactElement && value}
      {!isReactElement && (
        <InfoValue
          type={valueType}
          weight={valueWeight}
          color={valueColor}
          copyText={copyText || value}
          copyable={!!copyText || !!value}
        >
          {value ?? '-'}
        </InfoValue>
      )}
    </Wrapper>
  );
}
