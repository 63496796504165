import React, { useCallback, useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { listTagTypes } from '~/store/tagTypes/actions';
import { addTag, listTags, removeTag } from '~/store/tags/actions';
import useUserRoles from '~/hooks/useUserRoles';
import { TagResourceType } from '~/typings/enums/TagTypes';
import { useDispatch } from 'react-redux';
import saveTagTypes from '~/store/tagTypesSave/action';
import { Tags } from './ProfileTags.styled';

interface ProfileTagsProps {
  externalId: string | number;
  type: TagResourceType;
}

export function ProfileTags({ externalId, type }: ProfileTagsProps) {
  const { isAdmin, isSupervisor } = useUserRoles();
  const actionId = `${type}_${externalId}`;
  const data = { externalId, type };

  const listTagsControl = useAwaitControl(listTags);
  const tagsProfile = listTagsControl.result(actionId);
  const tagsSuccess = listTagsControl.isSuccessful(actionId);
  const tagsLoading = listTagsControl.isRunning(actionId);

  const listTagTypesControl = useAwaitControl(listTagTypes);
  const tagOptions = listTagTypesControl.result(type);
  const tagsTypesSuccess = listTagTypesControl.isSuccessful(type);
  const tagsTypesLoading = listTagTypesControl.isRunning(type);

  const removeTagControl = useAwaitControl(removeTag);
  const addTagControl = useAwaitControl(addTag);

  const dispatch = useDispatch();

  const tags = useMemo(
    () =>
      Array.isArray(tagsProfile)
        ? tagsProfile?.map(({ tagType, id }) => ({ ...tagType, tagId: id }))
        : [],
    [tagsProfile],
  );

  const handleRemoveTag = useCallback((tag) => {
    removeTagControl.start({ id: tag.tagId, data });
  }, []);

  const handleAddTag = useCallback(
    (tag) => {
      addTagControl.start({ externalId, tagTypeId: tag.id, type });
    },
    [externalId, type],
  );

  useEffect(() => {
    if (externalId && !tagsSuccess && !tagsLoading) {
      listTagsControl.start(data, { actionId });
    }
  }, [externalId, tagsSuccess, tagsLoading]);

  useEffect(() => {
    if (externalId && !tagsTypesSuccess && !tagsTypesLoading) {
      listTagTypesControl.start(data, { actionId: type });
    }
  }, [externalId, tagsTypesSuccess, tagsTypesLoading]);

  const handleCreateTag = (tagName: string) => {
    if (tagName && (isAdmin || isSupervisor)) {
      dispatch(saveTagTypes({ externalId, name: tagName, tagResourceType: type }));
    }
  };

  return (
    <Tags
      editable
      tags={tags}
      options={tagOptions}
      onClose={handleRemoveTag}
      onAdd={handleAddTag}
      closable={isAdmin || isSupervisor}
      onCreate={handleCreateTag}
    />
  );
}
