import styled, { css } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const CellLink = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  weight: 500,
  color: themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
  element: 'span',
}))<{ disabled?: boolean }>`
  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

type PriorityIndicatorProps = {
  colorConfig?: string;
  completed?: boolean;
};

export const PriorityIndicator = styled.div<PriorityIndicatorProps>`
  ${({ theme, colorConfig, completed }) => css`
    background: ${completed ? theme.colors.neutral.overPrimary : theme.colors[colorConfig].primary};
    border: 4px solid
      ${completed ? theme.colors.neutral.secondaryAlt : theme.colors[colorConfig].secondaryAlt};
    width: 16px;
    height: 16px;
    border-radius: 50%;
  `}
`;

export const CellWrapper = styled.div.attrs({
  className: 'cell-wrapper',
})<{ disabled?: boolean; ellipsis?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ theme }) => theme.colors.element.primary};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    `}
`;

export const TicketsWrapper = styled.div`
  max-height: calc(100vh - 540px);
  height: 100%;
  min-height: 300px;
  padding-right: 8px;

  .table-wrapper {
    padding: 24px 28px;
  }
`;

export const LinkToTicket = styled.b`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.brand.primary};
`;
