import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import _ from 'lodash';

import { OpportunityListItem } from '~/typings/entities/Opportunity';
import { Person } from '~/typings/entities/person';
import { getTicketsByUser } from '~/store/tickets/actions';
import { getOpportunities } from '~/store/opportunities/actions';
import { TabItem, Tabs, Tag } from '~/ui/components';

import { Contracts, GeneralHistory, Opportunities, Pendencies, Resets, Tickets } from '../Tabs';
import { Enrollments } from '../Tabs/Enrollment/Enrollment';
import { Files } from '../Tabs/Files/Files';

interface AuxiliaryInfoProps {
  loading?: boolean;
  person: Person;
}

export function AuxiliaryInfo({ person, loading }: AuxiliaryInfoProps) {
  const getTicketsByUserControl = useAwaitControl(getTicketsByUser);
  const opportunitiesControl = useAwaitControl(getOpportunities);
  const opportunities: OpportunityListItem[] = opportunitiesControl.result();
  const isTicketsLoading = getTicketsByUserControl.isRunning();

  const opportunityBadge = useMemo(() => {
    const availableOpportunities = opportunities?.filter(
      (opportunity) => opportunity.status === 'OK',
    );

    if (availableOpportunities?.length) {
      return (
        <Tag small rounded bgColor="brand.secondary" textColor="brand.primaryAlt">
          +{opportunities.length}
        </Tag>
      );
    }
  }, [opportunities]);

  const tabs = useMemo(() => {
    const tabsList: TabItem[] = [
      {
        key: 'tickets',
        title: 'Tickets',
        noPadding: true,
        component: <Tickets />,
      },
      {
        key: 'contratos',
        title: 'Contratos',
        noPadding: true,
        component: <Contracts />,
      },
      {
        key: 'opportunities',
        title: 'Oportunidades',
        badge: opportunityBadge,
        noPadding: true,
        component: <Opportunities />,
      },
      {
        key: 'matriculas',
        title: 'Matrículas',
        noPadding: true,
        component: <Enrollments />,
      },
      {
        key: 'history',
        title: 'Histórico geral',
        component: <GeneralHistory loading={loading} />,
        noPadding: true,
      },
      {
        key: 'pendencias',
        title: 'Pendências',
        noPadding: true,
        component: <Pendencies />,
      },
      {
        key: 'arquivos',
        title: 'Arquivos',
        noPadding: true,
        component: <Files id={person?.id} />,
      },
      {
        key: 'resets',
        title: 'Resets',
        noPadding: true,
        component: <Resets />,
      },
    ];

    return tabsList;
  }, [person, loading, isTicketsLoading, opportunities]);

  useEffect(() => {
    if (!_.isEmpty(person) && person?.registrationStatus !== 'REFUSED') {
      opportunitiesControl.start({ personId: person?.id, agreementId: person?.agreementId });
    }
  }, [person]);

  return (
    <Tabs loading={loading} tabs={tabs} persistOnQuery={{ key: 'aux' }} testId="auxiliary-info" />
  );
}
