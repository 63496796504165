import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import moment from 'moment';

import { Button, Input, Select, SelectDate } from '~/ui/components';
import { Contract } from '~/typings/entities/contract';
import CIVIL_STATE from '~/constants/MaritalStatus';
import { fetchFinancialInstitutionsForDeposit } from '~/store/utilities/actions';
import UFs from '~/constants/UFs';
import GENDERS from '~/constants/Genders';
import { validateKYC, getDetails } from '~/store/contracts/actions';
import { adjustContract, sendContract } from '~/store/consignee/actions';
import { useModal } from '~/hooks/useModal';
import { LOAN_TYPES } from '~/typings/enums/LoanType';
import toCPFFormat from '~/common/masked/toCPFFormat';
import { StyledForm } from '../Consignee.styled';

type ConsigneeProps = {
  contract: Contract;
  loanType: string;
};

const codProductText = `Código do produto em que a proposta será digitada.
    Caso seja Portabilidade, informe o código do produto do Refinanciamento da Portabilidade.`;

export function Form({ contract, loanType }: ConsigneeProps) {
  const { closeAll } = useModal();
  const form = useRef<WrappedFormUtils>();

  const getFinancialInstitutionsControl = useAwaitControl(fetchFinancialInstitutionsForDeposit);
  const validateKYCControl = useAwaitControl(validateKYC);
  const adjustContractControl = useAwaitControl(adjustContract);
  const sendContractControl = useAwaitControl(sendContract);
  const cardContractDetailsControl = useAwaitControl(getDetails);

  const adjustContractSuccessfully = adjustContractControl.isSuccessful();
  const sendContractSuccessfully = sendContractControl.isSuccessful();

  const financialInstitutions = getFinancialInstitutionsControl.result();
  const cardContract = cardContractDetailsControl.result();

  const mandatory = { rules: [{ required: true, message: 'Campo obrigatório!' }] };
  const selectorType = { label: 'name', value: 'value' };

  const usedFutureMargin = loanType === LOAN_TYPES.FUTUREMARGIN;

  const handleSubmit = useCallback(
    (data) => {
      const action = usedFutureMargin ? adjustContractControl : sendContractControl;
      const { contractId } = contract;
      delete data.document;

      action.start({ contractId, data });

      if (!usedFutureMargin) {
        validateKYCControl.start({ contractId });
      }
    },
    [usedFutureMargin],
  );

  const inputs = useMemo(() => {
    const defaultNationality = contract?.person?.gender === 'MALE' ? 'Brasileiro' : 'Brasileira';
    const FGTS_INPUTS = [
      'name',
      'motherName',
      'document',
      'birthDate',
      'zipCode',
      'street',
      'addressNumber',
      'district',
      'city',
      'state',
      'bankNumber',
      'checkingAccount',
      'checkingAccountDigit',
      'agency',
      'agencyDigit',
    ];

    const isBankInfoEnabled =
      contract?.benefitAccountType === 'Conta Corrente' &&
      contract?.person?.agreementName === 'INSS';

    const isCardContract = loanType === 'CONSIGNED_CARD_RMC';
    const personData = isCardContract ? cardContract?.personData : contract?.person;
    const bankData = isCardContract ? cardContract?.personData : contract;

    let initialInputs = [
      {
        id: 'name',
        label: 'Nome',
        title: 'Dados pessoais',
        initialValue: personData?.name,
        input: <Input label="Nome" />,
        options: mandatory,
      },
      {
        id: 'motherName',
        label: 'Nome da mãe',
        initialValue: personData?.motherName ?? personData?.mothersName,
        input: <Input label="Nome da mãe" />,
        options: mandatory,
      },
      {
        id: 'document',
        label: 'CPF',
        initialValue: toCPFFormat(personData?.document),
        input: <Input label="CPF" disabled={!!contract?.person?.document} />,
        options: mandatory,
        width: 158,
      },
      {
        id: 'registrationNumber',
        label: 'RG',
        initialValue: personData?.registrationNumber ?? personData?.registerNumber,
        input: <Input label="RG" />,
        options: mandatory,
        width: 146,
      },
      {
        id: 'registerNumberEmitter',
        label: 'Orgão Emissor',
        initialValue: contract?.person?.registerNumberEmitter,
        input: <Input label="Orgão Emissor" />,
        options: mandatory,
        width: 120,
      },
      {
        id: 'registerNumberState',
        label: 'UF',
        initialValue: [contract?.person?.registerNumberState],
        input: <Select options={UFs} label="UF" fieldNames={selectorType} />,
        options: mandatory,
        width: 113,
      },
      {
        id: 'registerNumberDateOfIssue',
        label: 'Data de emissão',
        initialValue: moment(contract?.person?.registerNumberDateOfIssue, 'DD-MM-YYYY'),
        input: <SelectDate />,
        options: mandatory,
        width: 163,
      },
      {
        id: 'birthDate',
        label: 'Data de nascimento',
        initialValue: moment(personData?.birthDate, 'YYYY-MM-DD'),
        input: <SelectDate />,
        options: mandatory,
        width: 201,
      },
      {
        id: 'gender',
        label: 'Sexo',
        initialValue: [contract?.person?.gender],
        input: <Select options={GENDERS} label="Sexo" fieldNames={selectorType} />,
        options: mandatory,
        width: 119,
      },
      {
        id: 'maritalStatus',
        label: 'Estado civil',
        initialValue: [personData?.maritalStatus],
        input: <Select options={CIVIL_STATE} label="Estado civil" fieldNames={selectorType} />,
        options: mandatory,
        width: 169,
      },
      {
        id: 'nationality',
        label: 'Nacionalidade',
        initialValue: contract?.person?.nationality ?? defaultNationality,
        input: <Input label="Nacionalidade" />,
        options: mandatory,
        width: 316,
      },
      {
        id: 'cityOfBirth',
        label: 'Cidade de nascimento',
        initialValue: contract?.person?.cityOfBirth,
        input: <Input label="Cidade de nascimento" />,
        options: mandatory,
        width: 316,
      },
      {
        id: 'stateOfBirth',
        label: 'Estado de nasc.',
        initialValue: contract?.person?.stateOfBirth,
        input: <Input label="Estado" />,
        options: mandatory,
        width: 113,
      },
      {
        id: 'zipCode',
        label: 'CEP',
        initialValue: personData?.zipCode,
        input: <Input label="CEP" />,
        options: mandatory,
        width: 194,
      },
      {
        id: 'street',
        label: 'Endereço',
        title: 'Endereço',
        initialValue: personData?.street,
        input: <Input label="Endereço" />,
        options: mandatory,
        width: 496,
      },
      {
        id: 'addressNumber',
        label: 'Número',
        initialValue: personData?.addressNumber,
        input: <Input label="Número" />,
        options: mandatory,
        width: 106,
      },
      {
        id: 'district',
        label: 'Bairro',
        initialValue: personData?.district,
        input: <Input label="Bairro" />,
        options: mandatory,
        width: 344,
      },
      {
        id: 'city',
        label: 'Cidade',
        initialValue: personData?.city,
        input: <Input label="Cidade" />,
        options: mandatory,
        width: 265,
      },
      {
        id: 'state',
        label: 'Estado',
        initialValue: personData?.state,
        input: <Input label="Estado" />,
        options: mandatory,
        width: 113,
      },
      {
        id: 'bankNumber',
        label: 'Banco',
        title: 'Dados bancários',
        initialValue: [bankData?.bankNumber],
        input: (
          <Select
            options={financialInstitutions}
            label="Banco"
            fieldNames={{ label: 'name', value: 'bankNumber' }}
            disabled={!isBankInfoEnabled}
          />
        ),
        options: mandatory,
      },
      {
        id: 'checkingAccount',
        label: 'Conta',
        initialValue: bankData?.checkingAccount,
        input: <Input label="Conta" disabled={!isBankInfoEnabled} />,
        options: mandatory,
        width: 228,
      },
      {
        id: 'checkingAccountDigit',
        label: 'Dígito',
        initialValue: bankData?.checkingAccountDigit,
        input: <Input label="Dígito" disabled={!isBankInfoEnabled} />,
        options: mandatory,
        width: 88,
      },
      {
        id: 'agency',
        label: 'Agência',
        initialValue: bankData?.agency,
        input: <Input label="Agência" disabled={!isBankInfoEnabled} />,
        options: mandatory,
        width: 228,
      },
      {
        id: 'agencyDigit',
        label: 'Dígito',
        initialValue: bankData?.agencyDigit,
        input: <Input label="Dígito" disabled={!isBankInfoEnabled} />,
        options: mandatory,
        width: 88,
      },
      {
        id: 'factorCode',
        label: 'Código do Produto na Consignatária',
        initialValue: contract?.factorCode,
        tooltip: 'test',
        input: <Input label="Código do Produto na Consignatária" />,
        options: mandatory,
      },
    ];

    if (contract?.loanType === LOAN_TYPES.FGTS_NEW) {
      initialInputs = initialInputs.filter((input) => FGTS_INPUTS.includes(input.id));
    }

    return initialInputs;
  }, [mandatory, financialInstitutions, cardContract]);

  useEffect(() => {
    if (adjustContractSuccessfully || sendContractSuccessfully) {
      closeAll();
      adjustContractControl.clear();
      sendContractControl.clear();
    }
  }, [adjustContractSuccessfully, sendContractSuccessfully]);

  return (
    <StyledForm id="form" ref={form} name="consignee-form" onSubmit={handleSubmit} inputs={inputs}>
      <Button rounded type="submit">
        {usedFutureMargin ? 'Salvar informações' : 'Enviar Contrato'}
      </Button>
    </StyledForm>
  );
}
