import React, { useEffect, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Button } from '~/ui/components';
import { ControlImage } from '~/components';
import { getFiles } from '~/store/files/actions';
import { getDetails } from '~/store/contracts/actions';
import { Flex } from './Image.styled';

type ImageProps = {
  id: number;
  loanType: string;
};

export function Image({ id, loanType }: ImageProps) {
  const [isFront, setIsFront] = useState(true);

  const getFilesControl = useAwaitControl(getFiles);
  const cardContractDetailsControl = useAwaitControl(getDetails);

  const files = getFilesControl.result(`CONTRACT_${id}`);
  const cardContract = cardContractDetailsControl.result();

  const cardFiles = cardContract?.formalizationData?.documents;
  const isCardContract = loanType === 'CONSIGNED_CARD_RMC';

  const getImage = (filesList) => {
    let image = { url: '' };
    const DOCUMENTFRONTRG = filesList.find((file) => file.type === 'DOCUMENTFRONT');
    const DOCUMENTBACKRG = filesList.find((file) => file.type === 'DOCUMENTBACK');

    if (DOCUMENTFRONTRG) {
      image = isFront ? DOCUMENTFRONTRG : DOCUMENTBACKRG;
    }
    return image.url;
  };

  useEffect(() => {
    const type = 'CONTRACT';
    getFilesControl.start({ type, id }, { actionId: `${type}_${id}` });
  }, []);

  return (
    <Flex id="image">
      <ControlImage alt="Documento" imagePath={getImage(isCardContract ? cardFiles : files)} />
      <Button onClick={() => setIsFront(!isFront)} rounded variant="outline" size="sm">
        {isFront ? 'Ver Verso' : 'Ver Frente'}
      </Button>
    </Flex>
  );
}
