import React, { forwardRef, useRef, useState } from 'react';
import { Divider } from 'antd';
import moment from 'moment';
import { DatePickerProps as AntDatePickerProps } from 'antd/es/date-picker/interface';

import { Calendar } from '~/ui/assets/icons';
import { StyledDatePicker, DatePickerContainer, TimeInput, CloseButton } from './DatePicker.styled';

export type DatePickerProps = AntDatePickerProps & {
  onChange?: (date: moment.Moment | null, dateString: string) => void;
  withTime?: boolean;
  minuteStep?: number;
};

export const DatePicker = forwardRef(
  (
    {
      onChange,
      placeholder = 'Selecione uma data',
      withTime,
      minuteStep,
      ...props
    }: DatePickerProps,
    ref,
  ) => {
    const [date, setDate] = useState<moment.Moment>(
      props?.value ? moment(props.value as moment.Moment) : null,
    );
    const [time, setTime] = useState<moment.Moment>(
      props?.value ? moment(props.value as moment.Moment) : null,
    );
    const dateRef = useRef<any>(ref);
    const hourFormat = 'HH:mm';

    const disabledDate = (current) => current && current < moment().startOf('day');

    const handleDateChange = (date, string) => {
      const newDate = moment(date).set({
        hour: time?.hour() || 12,
        minute: time?.minute() || 0,
        second: time?.second() || 0,
      });

      onChange?.(newDate, string);
      setTime(newDate);
      setDate(newDate);
    };

    const handleTimeChange = (newTime) => {
      const { format } = dateRef.current.props;

      const newDate = moment(date).set({
        hour: newTime.hour(),
        minute: newTime.minute(),
        second: newTime.second(),
      });

      onChange?.(newDate, newDate.format(format));
      setTime(newDate);
      setDate(newDate);
    };

    const handleClear = () => {
      onChange?.(null, null);
      setTime(null);
      setDate(null);
    };

    return (
      <DatePickerContainer id="date-picker">
        <Calendar width={20} height={20} />
        <StyledDatePicker
          ref={dateRef}
          dropdownClassName="mt-date-picker"
          disabledDate={disabledDate}
          placeholder={placeholder}
          {...props}
          onChange={handleDateChange}
        />

        {time && withTime && (
          <>
            <Divider className="divider" type="vertical" />
            <TimeInput
              minuteStep={minuteStep}
              value={time}
              format={hourFormat}
              onChange={handleTimeChange}
            />
          </>
        )}

        {date && <CloseButton onClick={handleClear} />}
      </DatePickerContainer>
    );
  },
);
