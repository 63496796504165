import React, { useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Person } from '~/typings/entities/person';
import { OldActions, Spinner } from '~/components';

import { getHisconByEnrollment } from '~/store/enrollments/actions';

type DownloadHisconProps = {
  person: Person;
};

export default function DownloadHiscon({ person }: DownloadHisconProps) {
  const hisconByEnrollmentControl = useAwaitControl(getHisconByEnrollment);
  const loading = hisconByEnrollmentControl.isRunning();
  const hisconLoaded = hisconByEnrollmentControl.isSuccessful();
  const hisconData = hisconByEnrollmentControl.result();

  const enrollmentId = person?.enrollmentId;

  const onPressOpenHiscon = useCallback(() => {
    hisconByEnrollmentControl.start({ enrollmentId });
  }, [enrollmentId]);

  if (person?.agreementName !== 'INSS') return null;

  useEffect(() => {
    if (hisconLoaded) {
      const downloadUrl = window.URL.createObjectURL(hisconData);

      window.open(downloadUrl, '__blank');
      window.URL.revokeObjectURL(downloadUrl);
    }

    return () => {
      hisconByEnrollmentControl.clear();
    };
  }, [hisconLoaded]);

  return (
    <OldActions
      disabled={loading}
      key="button"
      onClick={onPressOpenHiscon}
      title={loading ? 'Carregando...' : 'Abrir Hiscon'}
    />
  );
}
