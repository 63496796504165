import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router';
import { Modal, Select, Input, Form, Row, Col, message } from 'antd';

import { SearchSelect } from '~/common';
import { OldActions } from '~/components';
import { getPendingReasons } from '~/store/pendencies/actions';

const { Option } = Select;
const { Item: FormItem } = Form;

const pendeciesTypes = {
  DATA: {
    type: 'DATA',
    text: 'Dados',
  },
  AGREEMENT_PASSWORD: {
    type: 'AGREEMENT_PASSWORD',
    text: 'Integração com convênio',
  },
  ADDRESS: {
    type: 'ADDRESS',
    text: 'Endereço',
  },
  HISCON: {
    type: 'HISCON',
    text: 'Hiscon',
  },
  CONCESSION_LETTER: {
    type: 'CONCESSION_LETTER',
    text: 'Carta de concessão',
  },
  EXPERTISE: {
    type: 'EXPERTISE',
    text: 'Perícia',
  },
  PICTURE_FACE_FRONT_RECOGNITION: {
    type: 'FACEBIOMETRY',
    text: 'Assinatura Biométrica',
  },
  BANK_ACCOUNT_INVALID: {
    type: 'BANK_ACCOUNT',
    text: 'Conta Bancária',
  },
  PAYCHECK: {
    type: 'PAYCHECK',
    text: 'Contracheque',
  },
  PROOF_OF_ADDRESS: {
    type: 'PROOFOFADDRESS',
    text: 'Comprovante de endereço',
  },
  PICTURE_DOCUMENT: {
    type: 'DOCUMENTFRONT',
    text: 'Documentos',
  },
};

class PendenciesModal extends React.Component {
  static getPendingTypeOptions = (pendingReasons) => {
    const pendingTypeOptions = Object.keys(pendingReasons)
      .map((item) => (!pendeciesTypes[item] ? null : item))
      .filter((item) => !!item);

    return sortBy(pendingTypeOptions, (opt) => pendeciesTypes[opt].text);
  };

  state = {
    isShowModal: false,
    isShowOtherReason: false,
  };

  toggleIsShowModal = () => {
    const isShowModal = !this.state.isShowModal;

    if (!this.props.pendingReasons.ADDRESS) {
      this.props.dispatch(getPendingReasons.start());
    }

    this.setState({ isShowModal, isShowOtherReason: !isShowModal }, () => {
      if (!this.state.isShowModal) {
        this.props.form.resetFields();
      }
    });
  };

  onChangePendencieType = (value) => {
    this.props.form.setFieldsValue({
      pendeciesTypes: value,
      pendencieType: value,
      reasonType: undefined,
      reason: undefined,
      descriptionReasonType: undefined,
    });
  };

  onChangeValues = (field) => (event) => {
    const value = event?.target ? event.target.value : event;
    const { getFieldValue } = this.props.form;
    const pendencieType = getFieldValue('pendencieType');

    if (field === 'reasonType') {
      this.props.form.setFieldsValue({
        descriptionReasonType: undefined,
      });
    }

    this.props.form.setFieldsValue({
      [field]: value,
      ...(field === 'reasonType' &&
        value && {
          reason: this.props.pendingReasons[pendencieType].find((o) => o.reasonType === value)
            .reason,
        }),
    });

    if (field === 'reasonType') {
      if (value === 'OTHER') {
        this.setState({
          isShowOtherReason: true,
        });
      } else {
        this.setState({
          isShowOtherReason: false,
        });
      }
    }
  };

  onSendPendency = () => {
    this.props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        const { pendencieType, reason, reasonType, otherReason, descriptionReasonType } = values;
        const { type } = pendeciesTypes[pendencieType];

        const pendingReasonId = this.props.pendingReasons[pendencieType].find(
          (reason) => reason.reasonType === reasonType,
        ).id;

        const { reasonDetailsId } = this.props.pendingReasons[pendencieType].find(
          (reason) => reason.reasonType === reasonType,
        );

        if (reasonType === 'OTHER' && !otherReason) {
          message.error('Você precisa digitar o campo de descrição de motivo.');
          return;
        }

        this.props.onSendPendencie({
          pendencieType: type,
          reasonType,
          reason: otherReason || reason,
          pendingReasonId,
          reasonDetailsId: reasonDetailsId ?? Number(descriptionReasonType),
        });

        this.toggleIsShowModal();
      }
    });
  };

  selectFilter = (input, option) => SearchSelect(input, option.props.children);

  renderOtherReason(): any {
    const { getFieldDecorator } = this.props.form;

    return (
      <Col xs={{ span: 24 }}>
        <FormItem label="Descrição do motivo">
          {getFieldDecorator('otherReason', {
            rules: [
              {
                required: true,
                message: 'Informe o motivo da pendência',
                whitespace: true,
              },
            ],
          })(
            <Input.TextArea
              onChange={this.onChangeValues('otherReason')}
              placeholder="Digite o motivo da pendência"
              maxLength={100}
            />,
          )}
        </FormItem>
      </Col>
    );
  }

  renderForm() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { pendingReasons } = this.props;
    const pendencieType = getFieldValue('pendencieType');
    if (!pendingReasons) return null;
    const reasonType = getFieldValue('reasonType');
    const reasons = pendingReasons[pendencieType];

    return (
      <Form colon={false}>
        <Row>
          <Col xs={{ span: 24 }}>
            <FormItem label="Tipo">
              {getFieldDecorator('pendencieType', {
                rules: [
                  {
                    required: true,
                    message: 'Informe o tipo da pendência',
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder="Selecione o tipo da pendência"
                  onChange={this.onChangePendencieType}
                  showSearch
                  optionFilterProp="children"
                  filterOption={this.selectFilter}
                >
                  {PendenciesModal.getPendingTypeOptions(pendingReasons).map((item) => (
                    <Option key={item}>{pendeciesTypes[item].text}</Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }}>
            <FormItem label="Motivo">
              {getFieldDecorator('reasonType', {
                rules: [
                  {
                    required: true,
                    message: 'Informe o motivo da pendência',
                  },
                ],
              })(
                <Select
                  disabled={!pendencieType}
                  style={{ width: '100%' }}
                  placeholder="Selecione um motivo da pendência"
                  onChange={this.onChangeValues('reasonType')}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={this.selectFilter}
                >
                  {reasons?.map((item) => (
                    <Option key={item.reasonType}>
                      {item.reasonType === 'OTHER' ? 'Outro' : item.title}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
          {this.state.isShowOtherReason && this.renderOtherReason()}
          {!this.state.isShowOtherReason &&
            reasonType &&
            reasons?.find((reason) => reason.reasonType === reasonType).reasonDetails !== null && (
              <Col xs={{ span: 24 }}>
                <FormItem label="Descrição do Motivo">
                  {getFieldDecorator('descriptionReasonType', {
                    rules: [
                      {
                        required: true,
                        message: 'Informe a descrição do motivo',
                      },
                    ],
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Selecione uma descrição do motivo"
                      onChange={this.onChangeValues('descriptionReasonType')}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={this.selectFilter}
                    >
                      {reasonType &&
                        reasons
                          ?.find((reason) => reason.reasonType === reasonType)
                          .reasonDetails?.map((item) => (
                            <Option key={item.id}>{item.reason}</Option>
                          ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            )}
        </Row>
      </Form>
    );
  }

  render() {
    return [
      <OldActions
        key="pendency_request"
        title="Solicitar Pendência"
        onPress={this.toggleIsShowModal}
      />,
      <Modal
        title="Solicitar uma Pendência"
        onOk={this.onSendPendency}
        okText="Solicitar"
        onCancel={this.toggleIsShowModal}
        visible={this.state.isShowModal}
      >
        {this.renderForm()}
      </Modal>,
    ];
  }
}

const WrappedPendenciesModal = Form.create()(PendenciesModal);

function mapStateToProps(state) {
  return {
    pendingReasons: getPendingReasons.getResult()(state),
    isLoadingPendingReasons: getPendingReasons.isRunning()(state),
  };
}

export default compose(connect(mapStateToProps), withRouter)(WrappedPendenciesModal);
