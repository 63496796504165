import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import screenRegister, { HelmetTitle } from '~/hoc/screenRegister';
import useScreenSize from '~/hooks/useScreenSize';

import { Match } from '~/typings/components';
import {
  getContractsOfPerson,
  getFGTSOpportunity,
  getPersonDetail,
  getPersonImageUrl,
  getPersonResetHistory,
} from '~/store/people/actions';
import { listClixContracts } from '~/store/clix/actions';
import { getAllPendencies } from '~/store/pendencies/actions';
import { getTicketsByUser, updateTicket } from '~/store/tickets/actions';
import { Content } from '~/ui/components';
import { ProfileWithActions, ResponsiveMainActions, PersonActions } from '~/components';
import { formatName } from '~/common';
import { OtherOptions } from '~/components/OtherOptions';
import { MobileActions } from '~/components/ProfileResume/MobileActions/MobileActions';
import { getEnrollmentByUserId } from '~/store/enrollments/actions';
import usePersistTicketParams from '~/hooks/usePersistTicketParams';
import { PersonInfo, AuxiliaryInfo } from './components';

interface PersonDetailsProps {
  match?: Match;
}

export function PersonDetails({ match }: PersonDetailsProps) {
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getFGTSOpportunityControl = useAwaitControl(getFGTSOpportunity);
  const getPersonImageControl = useAwaitControl(getPersonImageUrl);
  const getContractsOfPersonControl = useAwaitControl(getContractsOfPerson);
  const getClixContractsControl = useAwaitControl(listClixContracts);
  const getPendenciesControl = useAwaitControl(getAllPendencies);
  const resetHistoryControl = useAwaitControl(getPersonResetHistory);
  const getTicketsByUserControl = useAwaitControl(getTicketsByUser);
  const updateTicketControl = useAwaitControl(updateTicket);
  const getEnrollmentByUserIdControl = useAwaitControl(getEnrollmentByUserId);

  const personId = match?.params?.personId;
  const personDetails = getPersonDetailsControl.result();
  const { contracts: clixContracts } = getClixContractsControl.result();
  const fgtsOpportunity = getFGTSOpportunityControl.result();

  const isPersonDetailsLoding = getPersonDetailsControl.isRunning();
  const isPersonClixDetailsLoading = getClixContractsControl.isRunning();
  const isFgtsOpportunityLoading = getFGTSOpportunityControl.isRunning();
  const isPersonImageLoading = getFGTSOpportunityControl.isRunning();
  const isResetHistoryLoading = resetHistoryControl.isRunning();
  const ticketSuccessfullyUpdated = updateTicketControl.isSuccessful();

  const avatarImage = getPersonImageControl.result('FACE_FRONT');

  const { persistTicketParams } = usePersistTicketParams(personId);

  const responsiveChangeSize = useScreenSize(`max-width: 1200px`);

  const mobileView = useMemo(() => responsiveChangeSize < 1200, [responsiveChangeSize]);

  const isLoading =
    isPersonDetailsLoding ||
    isPersonClixDetailsLoading ||
    isFgtsOpportunityLoading ||
    isPersonImageLoading ||
    isResetHistoryLoading;

  useEffect(() => {
    if (personDetails?.document) {
      getClixContractsControl.start({ document: personDetails.document });
    }
  }, [personDetails]);

  useEffect(() => {
    if (personId) {
      getFGTSOpportunityControl.start({ personId });
      getPersonDetailsControl.start({ id: personId });
      getPersonImageControl.start({ id: personId, type: 'FACE_FRONT' }, { actionId: 'FACE_FRONT' });

      persistTicketParams();
    }

    return () => {
      getPersonDetailsControl.clear();
      getClixContractsControl.clear();
      getFGTSOpportunityControl.clear();
      getContractsOfPersonControl.clear();
      getPendenciesControl.clear();
      resetHistoryControl.clear();
      getTicketsByUserControl.clear();
      getPersonImageControl.clear();
    };
  }, [personId]);

  useEffect(() => {
    if (ticketSuccessfullyUpdated) {
      getTicketsByUserControl.start({ personId });
      getEnrollmentByUserIdControl.start({ personId });
    }
  }, [ticketSuccessfullyUpdated]);

  return (
    <>
      <HelmetTitle title={formatName(personDetails?.name, 2) || 'Pessoa'} />

      {!mobileView && (
        <ProfileWithActions loading={isLoading} person={personDetails} avatarImage={avatarImage}>
          <PersonActions className="side-actions" loading={isLoading} />
          <OtherOptions className="other-options" loading={isLoading} />
        </ProfileWithActions>
      )}

      <Content>
        {!!mobileView && (
          <ResponsiveMainActions
            person={personDetails}
            loading={isLoading}
            avatarImage={avatarImage}
            responsiveActions={<MobileActions />}
          />
        )}

        <PersonInfo
          loading={isLoading}
          person={personDetails}
          clixContracts={clixContracts}
          fgtsOpportunity={fgtsOpportunity}
        />

        <AuxiliaryInfo loading={isLoading} person={personDetails} />
      </Content>
    </>
  );
}

export default screenRegister({
  screenName: 'PersonDetailsNew',
  path: '/backoffice/people/:personId',
  customClass: 'no-padding',
  customLayout: true,
})(PersonDetails);
